@import '../partials/_colors';

.pagerView {
	flex: 1;
}
.pageMainTitle {
	display: block;
	font-size: 18px;
	text-align: left;
	margin-bottom: 20px;
}
.noMargin {
	margin: 0 !important;
}
.addButton {
	right: 20px;
	padding: 0;
	width: 50px;
	height: 50px;
	bottom: 20px;
	position: fixed;
	border-radius: 50%;
	.addIconStyle {
		width: 20px;
	}
}

.textStyle {
	font-size: 40px;
	font-weight: 300;
	line-height: 55px;
}

.addButtonWrapper {
	z-index: 4;
	right: 20px;
	position: absolute;
	// bottom: Dimensions.get('window').height < 680 ? 20 : 40;
}
.textMargin {
	margin-top: 20px;
}
.titleWrapper {
	width: 100%;
	margin-bottom: 10px;
}
.cheqiListWrapper {
	padding: 20px;
}
.emptyWrapper {
	height: calc(100vh - 123px) !important;
}
.centerContainer {
	flex: 1;
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}
.circleContainer {
	top: 0;
	bottom: 0;
	z-index: 3;
	width: 100%;
	position: 'absolute';
}
.tabsWrapper {
	flex-direction: 'row';
}
.tab {
	flex: 1;
	padding: 15px;
	border-bottom: 2px solid;
	align-items: center;
	background-color: white;
	border-color: $borderColor;
}
.tabsText {
	font-size: 16px;
	color: black;
	font-family: DMSans-Bold;
}
.activeTab {
	opacity: 1;
	border-color: $secondaryColor;
}
.activeTabText {
	color: $secondaryColor;
}
.refreshWrapper {
	flex: 1;
	align-items: center;
	justify-content: center;
}
.refreshIcon {
	width: 50;
	// height: null
	// aspectRatio: 1
}
