body,
html {
	width: 100%;
	height: 100%;
}

body *:not(script) {
	border: 0;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
