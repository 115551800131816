.modal {
	position: fixed;
	inset: 0; /* inset sets all 4 values (top right bottom left) much like how we set padding, margin etc., */
	background-color: rgba(0, 0, 0, 0.6);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	transition: all 0.3s ease-in-out;
	overflow: hidden;
	z-index: 999;
	opacity: 0;
	pointer-events: none;
}

.modal-enter-done {
	opacity: 1;
	pointer-events: auto;
	.modal-content {
		transform: translateY(0px);
	}
}

.modal-exit {
	opacity: 0;
	.modal-content {
		transform: translateY(-75x);
	}
}

.modal-content {
	transform: translateY(-75px);
	transition: transform 0.18s linear;
}
