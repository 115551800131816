@import '../partials/_colors';
@import '../partials/_breakpoints';

.payCheqiWrapper {
	display: flex;
	position: relative;
	flex-direction: column;
	min-height: calc(100vh - 77px);
	.pagerTitle {
		display: block;
		text-align: center;
	}
	.boxContainer {
		padding: 0px;
		cursor: initial;
		margin-top: 16px;
		overflow: hidden;
		border-radius: 8px;
		margin-bottom: 20px;
		background-color: white;
	}
	.buttonWrapper {
		display: flex;
		margin-top: auto;
		position: relative;
		flex-direction: column;
		justify-content: flex-end;
	}
	.chooseBank {
		padding: 12px;
		display: flex;
		cursor: pointer;
		border-radius: 40px;
		align-items: center;
		flex-direction: row;
		justify-content: center;
		background-color: $secondaryColor;
	}
	.idealIcon {
		width: 30px;
		height: 30px;
		margin-right: 10px;
	}
	.arrowIcon {
		right: 30px;
		width: 15px;
		height: 15px;
		fill: #fff;
		position: absolute;
	}
	.buttonTitle {
		color: #fff;
		margin: 0 16px;
		font-size: 16px;
		font-weight: 700;
	}
	.couponWrapper {
		display: flex;
		flex-direction: row;
	}
	.inputBox {
		border-radius: 6px;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		border: 1px solid $borderColor;
	}
	.addCoupon {
		padding: 12px;
		justify-content: center;
		border-top-right-radius: 6px;
		border-bottom-right-radius: 6px;
		background-color: $secondaryColor;
	}
	.errorMsg {
		color: red;
		font-size: 14px;
		margin-top: 8px;
	}
}

// .modalBg {
// 	top: 0;
// 	left: 0;
// 	right: 0;
// 	bottom: 0;
// 	width: 100vw;
// 	height: 100vh;
// 	position: fixed;
// 	background-color: rgba(0, 0, 0, 0.8);
// }

.bankModalWrapper {
	z-index: 5;
	width: 100%;
	padding: 20px;
	display: flex;
	position: relative;
	max-width: $mob-medium;
	justify-content: center;

	.closeButton {
		top: 18px;
		z-index: 5;
		right: 20px;
		position: absolute;
		background-color: transparent;
	}
	.closeIcon {
		width: 16px;
		fill: $borderColor;
	}
	.listWrapper {
		width: 100%;
		overflow: hidden;
		border-radius: 10px;
		position: relative;
		background-color: white;
	}
	.modalTitle {
		padding: 16px;
		text-align: center;
		border-bottom: 1px solid $borderColor;
	}
	.issuerWrapper {
		display: flex;
		overflow: auto;
		margin: 0 -8px;
		flex-wrap: wrap;
		max-height: 85vh;
		padding: 12px 20px;
		flex-direction: row;
		align-items: center;
	}
	.issuer {
		margin: 8px;
		border-radius: 10px;
		width: calc(33.33% - 16px);
	}
	.issuerThumbnail {
		width: 100%;
		margin-right: 16px;
		border-radius: 8px;
	}
}
