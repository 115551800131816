.verifySender {
	.popupBox {
		position: relative;
		.wrapper {
			margin: 0;
			padding: 0;
			width: 100%;
			display: flex;
			flex-direction: column;
			background: transparent;
			justify-content: flex-start;
			min-height: calc(100vh - 35px);
			div.bankList {
				flex: 1;
				width: 100%;
				margin: 10px 0 0;
				max-width: initial;
				max-height: initial;
				.bankItem {
					img {
						width: 100%;
						margin: 0 auto;
						max-width: 70px;
					}
					background-color: white;
					&.active {
						background-color: #ed8c00;
					}
				}
			}
			.errorbox {
				margin-top: 15px;
			}
		}
	}
}
