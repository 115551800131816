@import '../partials/_colors';

.fieldSet {
	&:not(:first-of-type) {
		margin-top: 20px;
	}
	label {
		display: block;
		font-weight: 700;
		margin-bottom: 10px;
	}
	span {
		display: block;
		font-size: 14px;
		margin-top: 8px;
		color: $WarningColor;
	}
}

.checkboxWrapper {
	display: flex;
	position: relative;
	align-items: flex-start;
	input {
		height: 18px;
		width: 18px;
		margin-right: 10px;
		margin-top: 2px;
		-webkit-appearance: none;
		-moz-appearance: none;
		-o-appearance: none;
		appearance: none;
		border: 1px solid $PrimaryColor;
		border-radius: 4px;
		outline: none;
		transition-duration: 0.3s;
		cursor: pointer;
		&:checked {
			border: 1px solid $PrimaryColor;
			& + small {
				display: block;
			}
		}
	}
	small {
		display: none;
		text-align: center;
		position: absolute;
		left: 3px;
		width: 12px;
		top: 5px;
		margin: 0;
		height: 12px;
		z-index: 1;
		border-radius: 2px;
		background: $PrimaryColor;
	}
	label {
		cursor: pointer;
		font-weight: normal;
		width: calc(100% - 30px);
	}
}

.pickupScreen,
.payresultScreen,
.claimedScreen,
.verifySender {
	min-height: 100vh;
	padding: 16px;
	display: flex;
	flex-direction: column;
	.wrapper {
		padding: 24px 24px 32px;
		border-radius: 5px;
		background: white;
		& > div:not(.fieldSet) {
			max-width: 350px;
			margin: 0 auto;
		}
		p {
			white-space: pre-line;
		}
	}
	.buttonStyle {
		height: 60px;
		font-size: 16px;
		position: relative;
		svg {
			position: absolute;
			right: 24px;
		}
	}
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

.noborder {
	border: 0px;
}

.inputField {
	width: 100%;
	font-size: 16px;
	box-shadow: none;
	padding: 15px 18px;
	border-radius: 4px;
	background: transparent;
	border: 2px solid #000;
	text-transform: lowercase;
	input {
		border-radius: 0px;
		text-transform: lowercase;
	}
}

.inputStyles {
	height: 22px;
	font-size: 16px;
	line-height: 22px;
	color: #000;
	width: 100% !important;
}
.otpField {
	padding-left: 0;
	padding-right: 0;
}
.inputSeparator {
	width: 1px;
	height: 22px;
	background: #d6d6d6;
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	-webkit-box-shadow: 0 0 0px 1000px white inset;
	background: #f5f2f2;
}

.termscontditionpara {
	em {
		display: inline;
		color: $PrimaryColor;
	}
}

.errorbox {
	padding: 10px;
	display: block;
	font-size: 14px;
	margin-top: 30px;
	border-radius: 4px;
	color: $WarningColor;
	border: 1px solid $WarningColor;
	background-color: rgba($WarningColor, 0.1);
}

.popupBox {
	top: 0;
	left: 0;
	z-index: 2;
	width: 100%;
	height: 100%;
	display: flex;
	overflow: auto;
	position: fixed;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: center;
	.background {
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		position: fixed;
		background-color: rgba(black, 0.8);
	}
	.wrapper {
		z-index: 2;
		width: 90%;
		padding: 20px;
		max-width: 600px;
		margin: 50px auto;
		position: relative;
		background-color: white;
		h2 {
			margin: 0;
		}
		&.active {
			div.bankList {
				max-height: 1000px;
			}
			.otherbanks {
				display: none;
			}
		}
	}
	.otherbanks {
		display: block;
		font-size: 16px;
		cursor: pointer;
		font-weight: 300;
		margin: 10px auto 0;
		color: $PrimaryColor;
		background: transparent;
		text-decoration: underline;
	}
	div.bankList {
		display: flex;
		flex-wrap: wrap;
		max-width: 100%;
		margin-top: 10px;
		max-height: 120px;
		overflow: hidden;
		transition: all 0.2s linear;
		justify-content: space-between;
		@media (min-width: 395px) {
			max-height: 110px;
		}
		.bankItem {
			padding: 8px;
			display: flex;
			cursor: pointer;
			text-align: left;
			margin: 10px 0 5px;
			border-radius: 5px;
			font-weight: normal;
			align-items: center;
			width: calc(50% - 5px);
			background-color: white;
			justify-content: flex-start;
			border: 1px solid $PrimaryColor;
			img {
				width: 30px;
				border-radius: 5px;
				margin: 0 10px 0 0;
			}
			span {
				font-size: 13px;
			}
			&.active {
				background-color: $PrimaryColor;
				span {
					color: #fff;
				}
			}
		}
	}
	.buttonStyle {
		width: 100%;
	}
}

.redirectBox {
	@extend .errorbox;
	color: $PrimaryColor;
	border: 1px solid $PrimaryColor;
	background-color: rgba($PrimaryColor, 0.1);
}

.payresultScreen {
	padding: 0;
	height: 100vh;
	max-width: 100%;
	overflow: hidden;
	background-color: white;
	.payresultsWrapper {
		height: 100%;
		display: flex;
		flex-direction: column;
	}
	.contentSection {
		color: white;
		margin: 0 auto;
		max-width: 400px;
		padding: 50px 5% 0;
		text-align: center;
		background-color: transparent;
		p {
			font-size: 18px;
		}
	}
	img:not(.cheqiLogo) {
		height: 100%;
		margin: 0 auto;
		display: block;
		object-fit: contain;
		object-position: bottom;
	}
	.buttonStyle {
		left: 50%;
		z-index: 2;
		bottom: 12%;
		min-width: 240px;
		position: absolute;
		transform: translateX(-50%);
	}
}
