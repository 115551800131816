@import './_breakpoints';

.sectionTitle {
	font-size: 40px;
	text-align: center;
	@media (max-width: $mob-large) {
		text-align: left;
	}
	@media (min-width: $mob-small+1) and (max-width: $mob-large) {
		font-size: 5vw;
	}
	@media (max-width: $mob-small) {
		font-size: 24px;
	}
}
