@import './_fonts';
@import './_colors';
@import './_breakpoints';

:root {
	--swiper-theme-color: #ed8c00 !important;
}

html {
	scroll-behavior: smooth;
}

body {
	margin: 0;
	font-size: 16px;
	font-weight: 400;
	color: $TextColor;
	overflow-x: hidden;
	background: white;
	font-family: $PrimaryFont;
}

h1,
h2,
h3,
h4,
h5,
h6,
button,
strong {
	font-weight: 700;
	font-family: inherit;
}

a {
	color: $TextColor;
	text-decoration: none;
}

p {
	line-height: 24px;
	a {
		vertical-align: top;
		display: inline;
	}
}

.underConstruction {
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #f58e02;
	img {
		max-width: 100vw;
		max-height: 100vh;
	}
}

.noborder {
	border: 0px !important;
}

input,
select,
textarea,
button {
	border: 0;
	outline: none;
}

.headingStyle {
	font-size: 24px;
	font-weight: 00;
	line-height: 32px;
}

.flexCenter {
	display: flex;
	align-items: center;
	justify-content: center;
}

.inlineFlexCenter {
	align-items: center;
	display: inline-flex;
	justify-content: center;
}

.blockSection {
	padding: 80px 5%;
	overflow-x: hidden;
	@media (max-width: $mob-large) {
		padding: 50px 5%;
	}
}

.maxFrameSize {
	max-width: 600px;
	margin: 0 auto;
}

.cheqiLogo {
	left: 50%;
	width: 150px;
	position: absolute;
	transform: translateX(-50%);
	@media (max-width: $mob-large) {
		width: 120px;
	}
}

.seprator {
	width: 100%;
	height: 1px;
	display: block;
	background: $SeparateColor;
}

.grayBackground {
	background: $backgroundColor;
}

.themeBackgound {
	background: $PrimaryColor;
}

.gridColumn {
	width: 100%;
	margin: 0 auto;
	max-width: 1140px;
}

.slick-slider {
	-webkit-user-select: unset !important;
	user-select: unset !important;
}

.mainContainer {
	width: 100%;
	overflow-x: hidden;
}

.mobileFrame {
	z-index: 2;
	width: 100%;
	margin: 0 auto;
	min-height: 100vh;
	position: relative;
	background-color: $backgroundColor;
}

.boxContainer {
	width: 100%;
	padding: 0 16px;
	cursor: pointer;
	margin-bottom: 20px;
	border-radius: 10px;
	background-color: white;
}

// order card styles start
.cheqiBox {
	padding: 15px 0;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.cheqiTitle {
	font-size: 14px;
	font-weight: 700;
}

.cheqiAmount {
	font-size: 16px;
}

.separator {
	height: 1px;
	background-color: $borderColor;
}

.cheqiDate {
	font-size: 14px;
	font-weight: 400;
}

.cheqiButton {
	padding: 6px 8px;
	border-radius: 20px;
}
// order card styles end

.canceledTextStyle {
	font-size: 10px;
}

.safeareaWrapper {
	flex: 1;
	background-color: $backgroundColor;
}
.primaryBackground {
	background-color: $backgroundColor;
}
.headerWrapper {
	top: 0px;
	z-index: 2;
	position: sticky;
	background-color: white;
}
.headerRow {
	display: flex;
	padding: 16px 20px;
	align-items: center;
	justify-content: space-between;
}
.pageWrapper {
	flex: 1;
}
.pageTitle {
	font-size: 22px;
	text-align: center;
	font-weight: 700;
}
.pageContent {
	margin-bottom: 20px;
	font-size: 14px;
	line-height: 20px;
}
.contentMargin {
	margin-bottom: 10px;
}
.errorText {
	font-size: 14px;
	line-height: 18px;
	margin-top: 16px;
	color: tomato;
}
.pagerTitle {
	font-size: 18px;
	font-weight: 700;
	text-align: center;
}
.pagerDescription {
	margin-bottom: 3%;
	text-align: center;
	font-size: 15px;
}
.boldText {
	font-weight: 700;
}
.lowerText {
	text-transform: lowercase;
}
.colorWhite {
	color: white;
}
.colorSecondary {
	color: $secondaryColor;
}
.underlineText {
	text-decoration: underline;
}
.strongContent {
	font-size: 16px;
	margin-bottom: 20px;
	color: $secondaryColor;
	font-weight: 700;
}
.lottieImage {
	width: 50%;
	align-self: center;
}
.cancelButton {
	cursor: pointer;
	margin-top: 20px;
	align-self: center;
	background-color: transparent;
}
.cancelText {
	font-size: 16px;
	font-weight: 700;
}
.viewPagerWrapper {
	flex: 1;
	padding: 20px;
	padding-top: 10%;
	background-color: $backgroundColor;
}
.inputBox {
	flex: 1;
	padding: 16px;
	border-radius: 6px;
	text-align: left;
	color: $textColor;
	font-size: 16px;
	background-color: white;
	font-weight: 400;
	&::placeholder {
		color: $placeholderTextColor;
	}
}
.clearMargin {
	margin-top: 0;
	margin-bottom: 0;
	margin: 0 !important;
}
.keyboardContainer {
	background-color: $backgroundColor;
}

// styles from physical cheqi
.skipButton {
	z-index: 2;
	display: flex;
	color: $TextColor;
	padding: 8px 15px;
	border-radius: 40px;
	justify-content: center;
	background-color: $buttonPrimaryColor;
}
.pageContainer {
	flex: 1;
}
.pagerdiv {
	flex: 1;
}
.buttonsWrapper {
	left: 0;
	right: 0;
	z-index: 2;
	bottom: 20px;
	display: flex;
	padding: 0 20px;
	position: absolute;
	align-items: center;
	justify-content: space-between;
}
.nextButton {
	padding: 12px 24px;
	border-radius: 25px;
	pointer-events: all;
}
.exculsiveText {
	flex: 1;
	font-size: 10;
	display: flex;
	margin-right: 10;
	flex-wrap: wrap;
}
.loaderPadding {
	padding-top: 35%;
}

// headerbanner styles
.headerBanner {
	z-index: 9;
	display: flex;
	padding-left: 20px;
	position: relative;
	align-items: center;
	justify-content: space-between;
	.bannerText {
		font-size: 16px;
		color: white;
	}
	.bannerDetails {
		top: 38px;
		left: 0;
		right: 0;
		z-index: 9;
		position: absolute;
		padding: 10px 20px 15px;
	}
	.iconWrapper {
		display: flex;
		padding: 12px;
		margin-right: 8px;
		align-items: center;
		justify-content: center;
		background-color: transparent;
	}
	.icon {
		width: 15px;
		fill: white;
		transition: transform 0.2s linear;
		&.active {
			transform: rotate(45deg);
		}
	}
}

// recievername styles
.recieverNameWrapper {
	display: flex;
	align-items: center;
	flex-direction: column;
	.image {
		width: 60%;
		margin-top: 5%;
		max-width: 300;
		margin-bottom: 10%;
		object-fit: contain;
	}
	.contactWrapper {
		width: 95%;
		display: flex;
		overflow: hidden;
		position: relative;
		border-radius: 6px;
		background-color: white;
		.contactBtn {
			display: flex;
			padding: 0 16px;
			align-items: center;
			justify-content: center;
			background-color: $greenColor;
		}
		.contactIcon {
			fill: white;
			height: 25px;
		}
	}
}

// cheqiAmountWrapper styles
.cheqiAmountWrapper {
	height: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	min-height: calc(100vh - 116px);
	.pricesButtonsWrapper {
		display: flex;
		flex-wrap: wrap;
		margin-top: 20px;
		position: relative;
		align-items: flex-start;
		justify-content: space-between;
	}
	.button {
		width: 31%;
		color: #000;
		padding: 4% 5px;
		border-radius: 6px;
		margin-bottom: 12px;
		align-items: center;
		border: 2px solid white;
		background-color: white;
	}
	.buttonText {
		font-size: 14px;
	}
	.activeButton {
		border-color: $greenColor;
	}
	.activeButtonText {
		color: $greenColor;
	}
}

// modal styles
.PopupModal {
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	position: fixed;
	background-color: rgba(0, 0, 0, 0.8);
}

.modelWrapper {
	border-radius: 10px;
	background-color: white;
	.modalHeader {
		padding: 20px;
		border-bottom: 1px solid $borderColor;
	}
	.modalBody {
		padding: 20px;
	}
	.inputBox {
		width: 100%;
		padding: 14px;
		margin-top: 10px;
		border: 1px solid $borderColor;
	}
	.modelButtonsWrapper {
		bottom: 0;
		padding: 0;
		margin-top: 20px;
		position: relative;
		justify-content: flex-end;
	}
	.modelButton {
		margin-left: 16px;
		padding: 12px 24px;
		border-radius: 6px;
	}
}

// cardMessageWrapper styles
.cardMessageWrapper {
	text-align: center;
	.inputBox {
		width: 100%;
		height: 200px;
		margin-top: 10px;
		text-align: left;
		margin-bottom: 16px;
		justify-content: flex-start;
		font-family: 'Kalam', cursive;
	}
}

// contact details styles
.contactDetailsWrapper {
	text-align: center;
	.dateButton {
		width: 100%;
		display: flex;
		overflow: hidden;
		border-radius: 6px;
		flex-direction: row;
		background-color: white;
	}
	.dateIcon {
		height: 53px;
		display: flex;
		padding: 0 16px;
		align-items: center;
		justify-content: center;
		background-color: $greenColor;
	}
	.calendarIcon {
		height: 25px;
		fill: white;
	}
	.sectionTitle {
		display: block;
		font-size: 16px;
		font-weight: 700;
		margin-top: 20px;
		text-align: center;
		margin-bottom: 8px;
		color: $secondaryColor;
	}
	.row {
		display: flex;
		flex-direction: row;
	}
	.marginHorizontal {
		margin: 0 12px;
	}
	.marginLeft {
		margin-left: 8px;
	}
	.marginTop {
		margin-top: 16px;
	}
	.acceptWrapper {
		display: flex;
		margin-top: 10%;
		flex-wrap: wrap;
		margin-bottom: 80px;
		flex-direction: row;
		background-color: transparent;
	}
	.termsText {
		flex: 1;
		margin-top: -2px;
		text-align: left;
		color: $TextColor;
	}
	.checkBox {
		width: 20px;
		height: 20px;
		margin-right: 10px;
		border-radius: 2px;
		background-color: white;
		border: 1px solid $borderColor;
	}
	.active {
		background-color: $buttonSecondaryColor;
	}
	.markIcon {
		width: 18px;
		fill: white;
	}
	.errorText {
		display: block;
		text-align: left;
		font-style: italic;
		color: $buttonActiveColor;
	}
	.inputBox {
		width: 100%;
	}
}
.calendarWrapper {
	flex: 1;
	display: flex;
	margin: 0 20px;
	min-height: 100vh;
	align-items: center;
	justify-content: center;

	.calendarBackground {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
		position: fixed;
		background-color: rgba(0, 0, 0, 0);
	}
	.calendar {
		z-index: 2;
		border-radius: 20px;
		background-color: white;
		.errorText {
			display: block;
			margin: 0 20px 20px;
		}
	}
	.Calendar {
		box-shadow: none;
		.Calendar__day.-selected {
			background: $secondaryColor !important;
		}
		.Calendar__monthYear > * {
			color: $TextColor;
		}
	}
}

// introduction styles
.introContainer {
	display: flex;
	width: 100vw;
	height: 100vh;
	background-color: #fff;
	.imageWrapper {
		z-index: 1;
		width: 100%;
		height: 100vh;
		display: flex;
		position: relative;
		align-items: flex-end;
		justify-content: flex-end;
		.introImage {
			width: 100%;
			max-width: 100%;
			max-height: 100vh;
			object-fit: contain;
		}
	}
	.nextButton {
		left: 50%;
		z-index: 2;
		bottom: 20px;
		border-radius: 40px;
		padding: 14px 40px;
		align-self: center;
		position: absolute;
		transform: translateX(-50%);
		background-color: white;
	}
	.nextText {
		font-weight: 700;
		color: $secondaryColor;
	}
	.skipButton {
		top: 20px;
		right: 20px;
		padding: 8px 25px;
		position: absolute;
	}
	.skipText {
		font-weight: 700;
	}
}

// card styles
.cardsWrapper {
	padding: 25px 0 0;
	text-align: center;
	.cardListWrapper {
		height: calc(65vh - 5px);
		.swiper {
			height: 100%;
			padding: 0 20px 30px;
			.swiper-slide {
				height: calc((100% - 10px) / 2) !important;
			}
			& div.swiper-pagination {
				bottom: 0px;
			}
			& div.swiper-button-next,
			& div.swiper-button-prev {
				color: #000;
				@media (max-width: $mob-medium) {
					display: none;
				}
			}
			.cardContainer {
				width: 100%;
				height: 100%;
				color: #000;
				background-color: transparent;
				.cardImage {
					width: 100%;
					object-fit: cover;
					border-radius: 8px;
					height: calc(100% - 25px);
					transition: all 0.1s linear;
				}
				.cardPrice {
					right: 10px;
					bottom: 50px;
					color: white;
					font-size: 11px;
					padding: 4px 10px;
					position: absolute;
					transform: rotate(5deg);
					background-color: $greenColor;
				}
				.cardTitle {
					display: block;
					margin-top: 2px;
				}
				&.activeCard {
					.cardImage {
						border: 4px solid $greenColor;
					}
					.cardTitle {
						color: $greenColor;
					}
				}
			}
		}
	}
}

//  cheqi overview styles
.cheqioverviewWrapper {
	.row {
		display: flex;
		margin-top: 15px;
		padding: 0 20px;
		flex-direction: row;
	}
	.column {
		flex: 1;
		display: flex;
		flex-direction: column;
	}
	.title {
		font-size: 15px;
	}
	.descr {
		font-size: 14px;
		margin-top: 5px;
		margin-right: 50;
	}
	.amount {
		font-size: 15px;
		font-weight: 700;
	}
	.boldText {
		font-size: 15px;
		font-weight: 700;
	}
	.totalWrapper {
		padding: 20px;
		color: white;
		background-color: $greenColor;
	}
}

// pay result styles
.payresultScreen {
	text-align: center;
	.pageContainer {
		display: flex;
		padding-top: 50px;
		flex-direction: column;
		height: calc(100vh - 77px);
	}
	.contentWrapper {
		padding: 0 20px;
		min-height: initial;
	}
	.nextButton {
		left: 50%;
		width: 70%;
		bottom: 8%;
		max-width: 600px;
		align-self: center;
		position: absolute;
		transform: translateX(-50%);
	}
	img {
		max-width: 100%;
		object-position: top;
	}
}

// create cheqi styles
.createCheqiComponent {
	.section {
		padding-bottom: 0;
	}
	.sectionTitle {
		font-size: 19px;
	}
	.row {
		display: flex;
		margin-bottom: 16px;
		flex-direction: row;
		justify-content: space-between;
	}
	.tagStyle {
		color: white;
		font-size: 14px;
		overflow: hidden;
		padding: 4px 8px;
		border-radius: 4px;
		align-self: center;
		background-color: $greenColor;
	}
	.giftCard {
		width: 90%;
		display: block;
		max-width: 400px;
		margin: 0 auto 25px;
	}
	.nextButton {
		width: 100%;
		padding: 14px 0;
		margin-bottom: 10px;
		svg {
			width: 15px;
			fill: #fff;
			margin-left: 15px;
			transform: rotate(180deg);
		}
	}
}

.amount-picker {
	margin: 0 20px;
}

.acceptWrapper {
	display: flex;
	margin-top: 10%;
	flex-wrap: wrap;
	margin-bottom: 80px;
	flex-direction: row;
	background-color: transparent;
}
.termsText {
	flex: 1;
	margin-top: -2px;
	text-align: left;
	color: $TextColor;
}
.checkBox {
	width: 20px;
	height: 20px;
	margin-right: 10px;
	border-radius: 2px;
	background-color: white;
	border: 1px solid $borderColor;
}
.active {
	background-color: $buttonSecondaryColor;
}
.markIcon {
	width: 18px;
	fill: white;
}
