$TextColor: #000;
$InactiveColor: #ccc;
$FieldColor: #ede8e8;
$HamburgerColor: #333;
$BorderColor: #ebebeb;
$PrimaryColor: #ed8c00;
$WarningColor: #e31b12;
$SeparateColor: #ebebeb;
$BackgroundColor: #f5f2f2;

// variables from application
$textColor: #000000;
$primaryColor: #f5f2f2;
$secondaryColor: #ed8c00;

$greenColor: #1cbc2a;
$activeColor: $secondaryColor;
$backgroundColor: $primaryColor;

$buttonPrimaryColor: #ebe5e5;
$buttonSecondaryColor: $secondaryColor;
$buttonActiveColor: #de4a00;
$buttonDisableColor: #cccccc;
$placeholderTextColor: #cccccc;
$borderColor: #cccccc;

$dotsColor: #b19a9b;
