@import '../partials/_colors';

.loadingWrapper {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9999;
	display: flex;
	position: fixed;
	padding-top: 15%;
	justify-content: center;
	background-color: rgba(255, 255, 255, 0.8);
}

.loader {
	width: 50px;
	height: 50px;
	display: block;
	border-radius: 50%;
	border: 6px solid #ebebeb;
	border-top: 6px solid $PrimaryColor;
	animation: spin 2s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
