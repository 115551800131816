@import '../partials/_colors';

.menuWrapper {
	top: 0;
	left: 0;
	bottom: 0;
	z-index: 1;
	opacity: 0;
	width: 280px;
	overflow: auto;
	position: fixed;
	visibility: hidden;
	overflow-x: hidden;
	background-color: $PrimaryColor;
	ul {
		width: 100%;
		display: flex;
		margin-bottom: 0;
		list-style: none;
		flex-flow: column;
		padding: 30px 20px;
		li {
			margin: 5px 0;
			a {
				width: 100%;
				color: #fff;
				display: flex;
				padding: 12px 0;
				align-items: center;
				img {
					width: 25px;
					margin-right: 15px;
				}
			}
		}
	}
}
