// desktop sizes
$extraLarge: 1920px;
$large: 1366px;
$medium: 1024px;
$small: 980px;
// mobile sizes
$mob-large: 768px;
$mob-medium: 680px;
$mob-small: 480px;
$mob-extraSmall: 360px;
