.termsandcondition {
	.wrapper {
		padding: 0 !important;
		height: calc(100% - 100px);
		iframe {
			z-index: 1;
			width: 100%;
			height: 100%;
			overflow: hidden;
			position: relative;
			border-radius: 10px;
		}
		.close {
			top: -30px;
			z-index: 5;
			right: 10px;
			background: none;
			position: absolute;
			svg {
				width: 20px;
			}
		}
	}
}
