@import './_fonts';
@import './_colors';

.buttonStyle {
	color: white;
	font-size: 16px;
	cursor: pointer;
	font-weight: 700;
	border-radius: 30px;
	padding: 10px 30px;
	align-items: center;
	display: inline-flex;
	justify-content: center;
	background: $PrimaryColor;
	transition: all 0.3s ease;
	border: 1px solid $PrimaryColor;
	// &:hover {
	// 	color: $PrimaryColor;
	// 	background: white;
	// }
}

.button {
	display: flex;
	padding: 16px;
	cursor: pointer;
	border: 1px solid;
	border-radius: 100%;
	align-items: center;
	justify-content: center;
}
.btnText {
	font-size: 14px;
	font-weight: 700;
}
.iconStyle {
	width: 20px;
	height: 20px;
}
