@import './_colors';
@import './_breakpoints';

.contentWrapper {
	min-height: 50vh;
	max-width: $mob-large;
	margin: 0px auto;

	h1 {
		font-size: 36px;
	}
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-size: 20px;
		margin-top: 50px;
	}
	img {
		max-width: 100%;
		object-fit: cover;
		margin: 50px auto 0;
	}
	p {
		margin: 20px 0;
		line-height: 26px;
	}
	a:not(.buttonStyle) {
		color: $PrimaryColor;
		&:hover {
			text-decoration: underline;
		}
	}
	.buttonStyle {
		margin-top: 20px;
	}
	ul,
	ol {
		margin: 0;
		padding: 0;
		padding-left: 10px;
		li {
			margin: 5px 0;
			line-height: 26px;
			ol,
			ul {
				margin-left: 30px;
			}
		}
	}
	@media (max-width: $mob-large) {
		padding: 0 calc(5% + 10px);
		h1 {
			font-size: 24px;
		}
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-size: 18px;
		}
	}
}
